import React, { useState } from "react";
import styles from "./Auth.module.scss";
import { useAuth } from "../../store/contexts/auth";
import { Link, Navigate, useNavigate } from "react-router-dom";
import LoginImage from "../../assets/login.png";
import classnames from "classnames";
import { TbBrandAuth0 } from "react-icons/tb";
import { notification } from "antd";

const Login = () => {
  const { login, user } = useAuth();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const res = await login(formData);
      console.log(res);
      if (res) {
        navigate("/");
        setLoading(false);
      } else {
        throw new Error("Something went wrong, Please try again.");
      }
    } catch (error) {
      setLoading(false);
      openNotification(
        "error",
        "Error",
        "top",
        error.message || "Something went wrong!"
      );
    }
  };

  if (user) {
    return <Navigate to="/" />;
  }

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type, message, placement, description) => {
    api[type]({
      message,
      description,
      placement,
    });
  };

  return (
    <>
      {contextHolder}
      <div className={styles.container}>
        <div className={styles.form_container}>
          <img src={LoginImage} alt="" />
          <p className="text-2xl font-bold text-center">Login</p>
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder="Email"
              />
            </div>
            <div className={styles.passwordContainer}>
              <label htmlFor="password">Password</label>
              <input
                type={showPassword ? "password" : "text"}
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
                placeholder="Password"
              />
            </div>
            <span
              onClick={() => {
                setShowPassword(!showPassword);
              }}
              className="cursor-pointer text-sm italic text-blue-400 block text-right"
            >
              {showPassword ? "Show Password" : "Hide Password"}
            </span>

            <button
              disabled={loading}
              type="submit"
              className={classnames({ "disabled-btn": loading })}
            >
              {loading ? "Please wait..." : "LOGIN"}
            </button>
            <span>
              Don't have an account{" "}
              <Link className="text-primary" to="/register">
                register
              </Link>{" "}
              here.
            </span>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
