import React, { useState, useEffect } from "react";
import Axios from "../../config/axios";
import moment from "moment";
import ListSkeleton from "../ui/list_skeleton";

const GetExpense = () => {
  const [expensesByDate, setExpensesByDate] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allExpenses, setAllExpenses] = useState([]);

  const token = localStorage.getItem("token");
  useEffect(() => {
    const fetchData = async (authToken) => {
      try {
        const response = await Axios.get("/expenses", {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        const groupedExpenses = groupExpensesByDate(response.data);
        setAllExpenses(response.data);
        setExpensesByDate(groupedExpenses);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching expenses:", error);
        setLoading(false);
      }
    };
    if (token) {
      fetchData(token);
    }
  }, [token]);

  const groupExpensesByDate = (expenses) => {
    const groupedExpenses = {};
    expenses.forEach((expense) => {
      const dateKey = moment(expense.date).format("D MMM");
      if (!groupedExpenses[dateKey]) {
        groupedExpenses[dateKey] = [];
      }
      groupedExpenses[dateKey].push(expense);
    });
    return groupedExpenses;
  };

  const totalAmountOfTheMonth = (expenses) => {
    const currentMonthExpenses = expenses.filter(
      (expense) => moment(expense.date).month() === moment().month()
    );
    let monthlyExpense = 0;
    currentMonthExpenses.forEach((expense) => {
      monthlyExpense += expense.amount;
    });
    return monthlyExpense;
  };

  const totalAmountOfTheDay = (expenses) => {
    let dailyExpense = 0;
    expenses.forEach((expense) => {
      dailyExpense += expense.amount;
    });
    return dailyExpense;
  };

  return (
    <>
      <div className="flex items-center justify-between mx-4 border p-4 rounded font-semibold text-lg overflow-hidden bg-muted blurred-bg">
        <h2>{moment().format("MMMM YYYY")}</h2>
        <h2>Total: ₹{totalAmountOfTheMonth(allExpenses).toFixed(2)}</h2>
      </div>
      <div>
        {loading ? (
          <ListSkeleton length={4} moreClasses="pt-4 px-4" />
        ) : (
          <div className="p-4 flex flex-col gap-4">
            {Object.entries(expensesByDate).map(([date, expenses]) => (
              <div key={date} className="border rounded overflow-hidden">
                <h3 className="flex items-center justify-between p-4 bg-muted text-foreground">
                  <strong>{moment(date).format("DD MMMM")}</strong>
                  <strong>Total: ₹ {totalAmountOfTheDay(expenses)}</strong>
                </h3>
                <ul className="flex gap-2 flex-col bg-white p-4">
                  {expenses.map((expense, i) => (
                    <li key={expense._id} className="flex gap-2 items-center">
                      <span>{i + 1}.</span>
                      <div className="flex items-center justify-between gap-4 w-full">
                        <span>
                          {expense.description.length > 20
                            ? `${expense.description.substring(0, 20)} ...`
                            : expense.description}
                        </span>{" "}
                        <span className="bg-primary text-white px-2 rounded">
                          ₹ {expense.amount}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default GetExpense;
