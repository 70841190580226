import React, { useEffect, useState } from "react";
import Sheet from "react-modal-sheet";
import Axios from "../../config/axios";
import styles from "./exp_tracker.module.scss";
import classnames from "classnames";
import { notification } from "antd";
import { useAuth } from "../../store/contexts/auth";

const CreateExpense = ({ isOpen, setOpen }) => {
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("");
  const [category, setCategory] = useState("");
  const [note, setNote] = useState("");
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [loading, setLoading] = useState(false);

  const [categories, setCategories] = useState([]);
  const { token } = useAuth();

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type, message, placement, description) => {
    api[type]({
      message,
      description,
      placement,
    });
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await Axios.get("/categories");
        setCategories(response.data);
      } catch (error) {
        console.error("Failed to fetch categories", error);
      }
    };
    fetchCategories();
  }, []);

  const clearStates = () => {
    setDescription("");
    setAmount("");
    setCategory("");
    setNote("");
    setDate(new Date().toISOString().slice(0, 10));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = {
      description,
      amount,
      category,
      note,
      date,
    };

    try {
      const response = await Axios.post("/expenses", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 201) {
        setLoading(false);
        openNotification(
          "success",
          "Success",
          "top",
          "Expense successfully created."
        );
        setOpen(false);
        clearStates();
      }
    } catch (error) {
      setLoading(false);
      openNotification(
        "error",
        "Error",
        "top",
        error.response.data.error || "Something went wrong."
      );
    }
  };

  return (
    <>
      {contextHolder}
      <Sheet isOpen={isOpen} onClose={() => setOpen(false)}>
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <form onSubmit={handleFormSubmit} className={styles.form}>
              <p className="text-lg italic text-gray-500 font-semibold">
                Create a new <span className="text-gray-400">expense</span>
                <span className="text-yellow-300">.</span>
              </p>
              <div className="h-[2px] w-[100px] rounded bg-yellow-300 m-0 p-0 mb-2"></div>
              <div className={styles.form_control}>
                <label>Description</label>
                <input
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Expense name or detail"
                />
              </div>
              <div className={styles.form_control}>
                <label>Amount</label>
                <input
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  placeholder="Amount spent"
                />
              </div>
              <div className={styles.form_control}>
                <label>Category</label>
                <select
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option value="">Select a category</option>
                  {categories.map((cat) => (
                    <option key={cat._id} value={cat._id}>
                      {cat.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className={styles.form_control}>
                <label>Note (Optional)</label>
                <textarea
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  placeholder="Extra details"
                />
              </div>
              <div className={styles.form_control}>
                <label>Date</label>
                <input
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </div>
              <button
                className={classnames({ "disabled-btn": loading })}
                type="submit"
              >
                {loading ? "CREATING PLEASE WAIT..." : "CREATE EXPENSE"}
              </button>
            </form>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    </>
  );
};

export default CreateExpense;
