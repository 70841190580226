import { Skeleton } from "antd";
import React from "react";

const ListSkeleton = ({ length, moreClasses = "" }) => {
  const skeletonArray = Array.from({ length });

  return (
    <div className={`flex gap-4 flex-col ${moreClasses}`}>
      {skeletonArray.map((_, index) => (
        <Skeleton
          key={index}
          active
          className="rounded p-4 shadow bg-slate-50"
        />
      ))}
    </div>
  );
};

export default ListSkeleton;
