import React from "react";
import classes from "./Header.module.scss";
import { useAuth } from "../../store/contexts/auth";
import { RiShutDownLine } from "react-icons/ri";
import Image from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { FaGithub } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const Header = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  return (
    <header className={classes.header}>
      <nav
        className="cursor-pointer"
        onClick={() => {
          navigate("/");
        }}
      >
        <div className="flex items-center h-10">
          <img className="h-full" src={Image} alt="" />
          <h2>
            Expen<span className="text-primary">z</span>ify
          </h2>
        </div>

        <div className="flex items-center justify-center gap-4">
          <a
            href="https://github.com/zahidkhan846"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaGithub />
          </a>{" "}
          <a
            href="https://twitter.com/zahidkhanzk846"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaXTwitter />
          </a>
        </div>
      </nav>
    </header>
  );
};

export default Header;
