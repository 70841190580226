import axios from "axios";

const Axios = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  withCredentials: true,
});

export const apiCall = async (method, url, payload, headers) => {
  let data;
  let contentType;

  if (payload instanceof FormData) {
    data = payload;
    contentType = "multipart/form-data";
  } else {
    data = payload || {};
    contentType = "application/json";
  }

  const config = {
    method,
    url,
    data,
    headers: {
      "Content-Type": contentType,
      ...headers,
    },
  };

  const response = await Axios.request(config);
  return response?.data;
};

export default Axios;
