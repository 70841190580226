import AppRouter from "./components/AppRouter";
import React from "react";

function App() {
  return (
    <React.Fragment>
      <AppRouter />
    </React.Fragment>
  );
}

export default App;
