import {
  Button,
  Flex,
  Form,
  Image,
  Input,
  Modal,
  Typography,
  message,
  Upload,
  Skeleton,
  notification,
} from "antd";
import React, { useState } from "react";
import { useAuth } from "../../store/contexts/auth";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getUser, updateProfile } from "../../api/user.service";
import { useEffect } from "react";
import classNames from "classnames";
import { GoVerified } from "react-icons/go";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

const Profile = () => {
  const { logout, token } = useAuth();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [user, setUser] = useState({ name: "", email: "" });
  const [phone, setPhone] = useState();

  const { isLoading, data } = useQuery({
    queryKey: ["profile"],
    queryFn: () => getUser(token),
  });
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type, message, placement, description) => {
    api[type]({
      message,
      description,
      placement,
    });
  };

  const { mutate, isPending } = useMutation({
    mutationFn: (payload) => updateProfile(token, payload),
    onSuccess: (data) => {
      openNotification(
        "success",
        "Success",
        "top",
        "Note successfully created."
      );
      queryClient.refetchQueries({ queryKey: ["profile"] });
      setOpenEditModal(false);
    },
    onError: (err) => {
      openNotification("error", "Error", "top", "Something went wrong.");
    },
  });

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
      queryClient.refetchQueries({ queryKey: ["profile"] });
    }
  };

  useEffect(() => {
    if (!data?.user) return;
    setUser({
      name: data.user.name,
      email: data.user.email,
    });
    setPhone(data.user.phone);
  }, [data?.user]);

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );
  return (
    <>
      {contextHolder}
      <div>
        <>
          {isLoading ? (
            <Flex className="justify-between mx-4 mb-4 p-4 bg-muted text-foreground rounded-lg overflow-hidden border">
              <Skeleton.Image active />
              <Flex className="flex-col gap-2">
                <Skeleton.Input active />
                <Skeleton.Input active />
                <Skeleton.Input active />
              </Flex>
            </Flex>
          ) : (
            <Flex className="justify-between mx-4 mb-4 bg-muted text-foreground rounded-lg overflow-hidden border">
              <div className="h-40 w-40">
                <Image
                  className="object-cover object-center h-full w-full"
                  src={
                    data?.user.photoUrl
                      ? data?.user.photoUrl
                      : `${process.env.REACT_APP_SERVER_URL}/uploads/profile/dummy.png`
                  }
                />
              </div>
              <Flex className="p-4 flex-col items-end justify-end">
                <h3 className="font-bold text-lg">{data?.user.name}</h3>
                <h4 className="text-gray-500 flex items-center gap-1">
                  {false ? (
                    <GoVerified color="#f97316" />
                  ) : (
                    <GoVerified color="#cccccc" />
                  )}{" "}
                  <span>{data?.user.email}</span>
                </h4>
                <h4 className="text-gray-500 flex items-center gap-1">
                  {" "}
                  {true ? (
                    <GoVerified color="#f97316" />
                  ) : (
                    <GoVerified color="#cccccc" />
                  )}{" "}
                  <span>{data?.user.phone}</span>
                </h4>
              </Flex>
            </Flex>
          )}
        </>
        <Flex className="items-center justify-between p-4 bg-muted border rounded-lg mx-4 mb-4 gap-4">
          <Typography.Text>Edit your profile details</Typography.Text>
          <Button
            className="bg-foreground"
            type="primary"
            onClick={() => {
              setOpenEditModal(true);
            }}
          >
            Edit Profile
          </Button>
        </Flex>
        <Flex className="items-center justify-between p-4 bg-muted border rounded-lg mx-4 mb-4 gap-4">
          <Typography.Text className="block mb-2">
            Update profile picture
          </Typography.Text>
          <Upload
            name="avatar"
            listType="picture-card"
            showUploadList={false}
            action={`${process.env.REACT_APP_SERVER_URL}/users/image-upload`}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            headers={{
              Authorization: `Bearer ${token}`,
            }}
          >
            {imageUrl ? (
              <img
                src={imageUrl}
                alt="avatar"
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Flex>
        <Flex className="items-center justify-between p-4 bg-muted border rounded-lg mx-4 mb-4 gap-4">
          <Typography.Text>Logout from app</Typography.Text>
          <Button type="primary" danger onClick={logout}>
            Logout
          </Button>
        </Flex>
        <Flex className="items-center justify-between p-4 bg-muted border rounded-lg mx-4 mb-4 gap-4">
          <Flex className="flex-col">
            <Typography.Text>Deactivate account</Typography.Text>
            <Typography.Text type="secondary">
              Reactivate your account anytime
            </Typography.Text>
          </Flex>

          <Button type="primary" onClick={() => {}}>
            Deactivate Account
          </Button>
        </Flex>{" "}
        <Flex className="items-center justify-between p-4 bg-muted border rounded-lg mx-4 gap-4">
          <Flex className="flex-col">
            <Typography.Text>Delete account</Typography.Text>
            <Typography.Text type="secondary">
              This will delete your account and all its data, your won't be able
              to recover it.
            </Typography.Text>
          </Flex>
          <Button type="primary" danger onClick={() => {}}>
            Delete Account
          </Button>
        </Flex>
      </div>
      <Modal
        title="Update profile"
        open={openEditModal}
        onCancel={() => {
          setOpenEditModal(false);
        }}
        footer={
          <Flex justify="flex-end" gap={8}>
            <Button
              type="default"
              onClick={() => {
                setOpenEditModal(false);
              }}
            >
              Close
            </Button>
            <Button
              type="primary"
              className={classNames({
                "disabled-btn": isPending,
              })}
              disabled={isPending}
              onClick={() => {
                mutate({ ...user, phone });
              }}
            >
              {isPending ? "Updating..." : "Update"}
            </Button>
          </Flex>
        }
      >
        <Form layout="vertical">
          <Form.Item label="Name">
            <Input
              placeholder="Full name"
              value={user.name}
              onChange={(ev) => {
                setUser({ ...user, name: ev.target.value });
              }}
            />
          </Form.Item>
          <Form.Item label="Email">
            <Input
              placeholder="email@provider.com"
              value={user.email}
              onChange={(ev) => {
                setUser({ ...user, email: ev.target.value });
              }}
            />
          </Form.Item>
          <Form.Item label="Phone">
            <PhoneInput
              className="border p-1 rounded-lg hover:border-primary"
              flags={flags}
              defaultCountry="IN"
              placeholder="Enter phone number"
              value={phone}
              onChange={setPhone}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Profile;
