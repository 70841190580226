// Form.js
import React, { useState } from "react";
import styles from "./Auth.module.scss";
import { useAuth } from "../../store/contexts/auth";
import { Link, Navigate, useNavigate } from "react-router-dom";
import RegisterImage from "../../assets/register.png";
import classNames from "classnames";

const Register = () => {
  const navigate = useNavigate();

  const { register, user } = useAuth();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    try {
      const res = await register(formData);
      if (res.status === 200) {
        setLoading(false);
        navigate("/login");
      }
      console.log(res);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  if (user) {
    return <Navigate to="/" />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.form_container}>
        <img src={RegisterImage} alt="" />
        <p className="text-2xl font-bold text-center">Register</p>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              placeholder="Name"
            />
          </div>
          <div>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              placeholder="Email"
            />
          </div>
          <div className={styles.passwordContainer}>
            <label htmlFor="password">Password</label>
            <input
              type={showPassword ? "password" : "text"}
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              placeholder="Password"
            />
          </div>
          <span
            onClick={() => {
              setShowPassword(!showPassword);
            }}
            className="cursor-pointer text-sm italic text-blue-400 block text-right"
          >
            {showPassword ? "Show Password" : "Hide Password"}
          </span>
          <button
            disabled={loading}
            type="submit"
            className={classNames({ "disabled-btn": loading })}
          >
            {loading ? "Please wait..." : "REGISTER"}
          </button>
          <span>
            Already have a account{" "}
            <Link to="/login" className="text-primary">
              login
            </Link>{" "}
            here.
          </span>
        </form>
      </div>
    </div>
  );
};

export default Register;
