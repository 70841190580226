import React, { useState } from "react";
import { MdFormatListBulletedAdd } from "react-icons/md";
import {
  Input,
  Form,
  Modal,
  notification,
  List,
  Button,
  Popconfirm,
} from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { createNote, deleteNote, getNotes } from "../../api/note.service";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "../../store/contexts/auth";
import { SiMicrosoftonenote } from "react-icons/si";
import ListSkeleton from "../ui/list_skeleton";
import moment from "moment";
import { FaRegTrashAlt } from "react-icons/fa";
import classNames from "classnames";
const Note = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [note, setNote] = useState("");

  const [singleNote, setSingleNote] = useState(null);

  const { token } = useAuth();

  const [api, contextHolder] = notification.useNotification();
  const queryClient = useQueryClient();
  const openNotification = (type, message, placement, description) => {
    api[type]({
      message,
      description,
      placement,
    });
  };
  const { isLoading, data } = useQuery({
    queryKey: ["notes"],
    queryFn: () => getNotes(token),
  });
  const { mutate, isPending } = useMutation({
    mutationFn: (payload) => createNote(token, payload),
    onSuccess: (data) => {
      openNotification(
        "success",
        "Success",
        "top",
        "Note successfully created."
      );
      queryClient.refetchQueries({ queryKey: ["notes"] });
      handleCancel();
    },
    onError: (err) => {
      openNotification("error", "Error", "top", "Something went wrong.");
      // handleCancel();
    },
  });

  const { mutate: deleteMutation } = useMutation({
    mutationFn: (payload) => deleteNote(token, payload?.id),
    onSuccess: (data) => {
      openNotification(
        "success",
        "Success",
        "top",
        "Note successfully deleted."
      );
      handleCancel();
      queryClient.refetchQueries({ queryKey: ["notes"] });
    },
    onError: (err) => {
      openNotification("error", "Error", "top", "Something went wrong.");
      handleCancel();
    },
  });

  const showModal = () => {
    setIsModalOpen(true);
  };
  const clearState = () => {
    setTitle("");
    setNote("");
  };
  const handleOk = () => {
    mutate({
      title,
      note,
    });
    clearState();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    clearState();
  };
  return (
    <>
      {contextHolder}
      <div className="relative">
        {isLoading ? (
          <ListSkeleton length={4} moreClasses="px-4" />
        ) : (
          <List
            className="mt-20 px-4 mb-20"
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<SiMicrosoftonenote size={32} color="#333" />}
                  title={
                    <span
                      className="cursor-pointer"
                      onClick={() => {
                        setSingleNote(item);
                      }}
                    >
                      {item.title}
                    </span>
                  }
                  description={moment(item.createdAt).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )}
                />
                <Popconfirm
                  placement="left"
                  title="Delete the task"
                  description="Are you sure to delete this task?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => {
                    deleteMutation({
                      id: item._id,
                    });
                  }}
                >
                  <Button type="text" danger>
                    <FaRegTrashAlt />
                  </Button>
                </Popconfirm>
              </List.Item>
            )}
          />
        )}
        <div className="fixed right-4 bottom-24 z-10">
          <button className="icon_button_custom" onClick={showModal}>
            <MdFormatListBulletedAdd />
          </button>
        </div>
      </div>
      <Modal
        title="Create a note"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={
          <Button
            className={classNames("w-full", {
              "disabled-btn": isPending,
            })}
            type="primary"
            onClick={handleOk}
            disabled={isPending}
          >
            {isPending ? "CREATING NOTE..." : "CREATE NOTE"}
          </Button>
        }
      >
        <Form name="basic" layout="vertical" autoComplete="off">
          <Form.Item label="Title">
            <Input value={title} onChange={(ev) => setTitle(ev.target.value)} />
          </Form.Item>

          <Form.Item label="Note">
            <ReactQuill theme="snow" value={note} onChange={setNote} />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={singleNote?.title}
        open={!!singleNote}
        onOk={() => {
          setSingleNote(null);
        }}
        onCancel={() => {
          setSingleNote(null);
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: singleNote?.note }} />
      </Modal>
    </>
  );
};

export default Note;
