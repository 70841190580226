import { apiCall } from "../config/axios";

export const getUser = (token) => {
  return apiCall(
    "GET",
    "/users",
    {},
    {
      Authorization: `Bearer ${token}`,
    }
  );
};
export const updateProfile = (token, payload) => {
  return apiCall("PUT", "/users", payload, {
    Authorization: `Bearer ${token}`,
  });
};
