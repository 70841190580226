import { apiCall } from "../config/axios";

export const createNote = (token, payload) => {
  return apiCall("POST", `/note/create`, payload, {
    Authorization: `Bearer ${token}`,
  });
};

export const deleteNote = (token, payload) => {
  return apiCall(
    "DELETE",
    `/note/${payload}`,
    {},
    {
      Authorization: `Bearer ${token}`,
    }
  );
};

export const getNotes = (token) => {
  return apiCall(
    "GET",
    "/note",
    {},
    {
      Authorization: `Bearer ${token}`,
    }
  );
};
