import React, { useEffect, useState } from "react";
import styles from "./Footer.module.scss";
import { IoAddSharp } from "react-icons/io5";
import CreateExpense from "../expence_tracker/create";
import { IoMdStats } from "react-icons/io";
import { CgProfile } from "react-icons/cg";
import { GoTasklist } from "react-icons/go";
import { FaNotesMedical } from "react-icons/fa6";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { GoHome } from "react-icons/go";

const Footer = () => {
  const [isOpen, setOpen] = useState(false);
  const [showHome, setShowHome] = useState(false);
  const navigate = useNavigate();

  const loc = useLocation();

  useEffect(() => {
    if (loc.pathname === "/") {
      setShowHome(false);
    } else {
      setShowHome(true);
    }
  }, [loc.pathname]);

  return (
    <>
      <footer className={styles.footer}>
        <button
          className={styles.footerMainBtn}
          onClick={() => {
            navigate("/history");
          }}
        >
          <GoTasklist />
        </button>
        <button
          className={styles.footerMainBtn}
          onClick={() => {
            navigate("/note");
          }}
        >
          <FaNotesMedical />
        </button>
        <button
          onClick={() => {
            if (showHome) {
              navigate("/");
              return;
            }
            setOpen(true);
          }}
          className={styles.footerMainBtn}
        >
          {showHome ? <GoHome /> : <IoAddSharp />}
        </button>
        <button
          className={styles.footerMainBtn}
          onClick={() => {
            navigate("/stats");
          }}
        >
          <IoMdStats />
        </button>{" "}
        <button
          className={styles.footerMainBtn}
          onClick={() => {
            navigate("/auth");
          }}
        >
          <CgProfile />
        </button>
      </footer>
      <CreateExpense isOpen={isOpen} setOpen={setOpen} />
    </>
  );
};

export default Footer;
